import EventEmitter from 'eventemitter3';
import { Web } from "sip.js";


export default class SpeechCloud extends EventEmitter {

    constructor(options) {
        super();

        this.options = options;
        this.config = undefined;
        this.isRecognizing = false;
        this.initialize_sip_later = Boolean(this.options.initialize_sip_later); 
        this._api_methods = null;
        this._api_events = null;

        this.sip_registered = false;
        this.speechcloud_session_started = false;
        this.speechcloud_audio_constraints = {};
        this.audio_track = null;

        this.audio_contraints = this.options.audio_contraints;

        if (!('uri' in this.options) && !this.options.uri) {
            throw 'Options does not contains SpeechCloud uri.';
        }

        if ('public_key' in this.options && !('private_key' in this.options)) {
            throw 'Options does contains public_key but does not contain private_key.';
        }
    }

    availableMethods() {
        return this._api_methods;
    }

    availableEvents() {
        return this._api_events;
    }

    init() {
        var uri = this.options.uri;

        // Get configuration from server and establish SIP and WS connection.
        const request = new XMLHttpRequest();
        request.open('GET', uri, true);

        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                // Parse received configuration.
                this.config = JSON.parse(request.responseText);

                this._initConnections();
            } else {
                // We reached our target server, but it returned an error.
                this.emit('ws_error', {status: request.status, text: request.statusText});
            }
        };

        request.onerror = () => {
            this.emit('ws_error', {status: request.status, text: request.statusText});
        };

        request.send();
    }

    terminate() {
        if (this._ws !== undefined || this._user !== undefined) {
            console.log('SpeechCloud terminate called');
        }

        if (this._user !== undefined) {
            let temp = this._user;
            this._user = undefined;
            console.log('SIP disconnect')
            temp.disconnect();
        };
        if (this._ws !== undefined) {
            let temp = this._ws;
            this._ws = undefined;
            console.log('SpeechCloud WebSocket closing');
            temp.close();
            console.log('SpeechCloud WebSocket closed');
        };
    }

    initializeSIP() {
        if (this._user !== undefined) {
            // SIP already started
            return
        };

        this.emit('_sip_initializing', this.config.client_id);
        this.emit('sip_initializing', this.config.client_id);

        let audio = undefined;
        if ('tts' in this.options && this.options.tts) {
            // Create <audio/> or find already existed in HTML.
            if (typeof(this.options.tts) === 'boolean') {
                audio = document.createElement('audio');
                document.body.appendChild(audio);
            } else {
                audio = document.querySelector('audio' + this.options.tts);
            }
        };

        
        let audio_constraints = {...this.audio_constraints, ...this.speechcloud_audio_constraints};

        if (Object.keys(audio_constraints).length != 0) {
            console.log("SIP audio WebRTC constraints:", audio_constraints);
        } else {
            audio_constraints = true;
        }

        this._userOptions = {
          aor: "sip:"+this.config.sip_username,
          reconnectionAttempts: 0,
          userAgentOptions: {
              logLevel: "warn",
              authorizationUsername: this.config.client_id,
              authorizationPassword: this.config.sip_password
          },
            
          media: {
            constraints: {
              audio: audio_constraints,
              video: false
            },
            remote: {
              audio: audio
            }
          }
        };

        this._user = new Web.SimpleUser(this.config.sip_wss, this._userOptions);
        
        this._user.delegate = {
            onRegistered: () => {
                console.log('SIP registered');
                this.emit('_sip_registered');
                this.emit('sip_registered');

                this.sip_registered = true;
                if (this.sip_registered && this.speechcloud_session_started) {
                    this._makeSIPCall();
                }
            },
            onUnregistered: () => {
                console.log('SIP unregistered');
                this.emit('sip_unregistered');

                this.sip_registered = false;
            },
            onServerDisconnect: () => {
                console.log('SIP server disconnected');
                this.emit('_sip_closed');
                this.emit('sip_closed');
                this.terminate();
            },
            onServerConnect: () => {
                console.log('SIP server connected, registering', this.config.sip_username);
                this.emit('sip_connected');
                this._user.register();
            },
            onCallCreated: () => {
                console.log('SIP call created');
            },
            onCallAnswered: () => {
                this.emit('sip_ready');
                console.log('SIP ready, call answered');
            },

            onCallHangup: () => {
                this.emit('sip_hangup');
                console.log('SIP call hangup');
            },
        };

        this._user.connect()
            .catch((error) => {
                console.error(`SIP failed to connect`);
                console.error(error);
            });
    }

    _makeSIPCall() {
        if (this._user.session !== undefined) {
            // Session already started
            return
        };
        
        if (this._user.session === undefined) {
            console.log('SIP making call');
            // Now registered, invite the speechcloud
            this._user.call("sip:speechcloud", {inviteWithoutSdp: false});
        }
    }

    _logSIPAudio() {
        let a = this._user.localMediaStream.getAudioTracks()[0];
        let s = a.getSettings();
        let msg = {};

        let log_msg0 = "";

        msg.label = a.label;
        if (a.stats?.averageLatency !== undefined) {
            msg.streamLatency = a.stats.averageLatency / 1000;
            log_msg0 = "mic:"+a.label+" streamLatency: " + msg.streamLatency;
            console.log('SIP using mic: ' + a.label + '; stream latency: ' + msg.streamLatency);
        } else {
            log_msg0 = "mic:"+a.label;
            console.log('SIP using mic: ' + a.label);
        };

        let log_msg = "";
        if (s.autoGainControl !== undefined) {
            log_msg += " autoGainControl:"+s.autoGainControl;
            msg.autoGainControl = s.autoGainControl;
        };

        if (s.echoCancellation !== undefined) {
            log_msg += " echoCancellation:"+s.echoCancellation;
            msg.echoCancellation = s.echoCancellation;
        };

        if (s.noiseSuppression !== undefined) {
            log_msg += " noiseSuppression:"+s.noiseSuppression;
            msg.noiseSuppression = s.noiseSuppression;
        };

        if (s.voiceIsolation !== undefined) {
            log_msg += " voiceIsolation:"+s.voiceIsolation;
            msg.voiceIsolation = s.voiceIsolation;
        };

        if (s.latency !== undefined) {
            log_msg += " latency:"+s.latency;
            msg.latency = s.latency;
        };

        if (s.sampleRate !== undefined) {
            log_msg += " sampleRate:"+s.sampleRate;
            msg.sampleRate = s.sampleRate;
        };

        if (s.sampleSize !== undefined) {
            log_msg += " sampleSize:"+s.sampleSize;
            msg.sampleSize = s.sampleSize;
        };

        console.log('SIP audio settings:'+log_msg);
        msg.log_msg = log_msg0 + " " + log_msg;
        this.emit('sip_audio', msg);
    }

    _initConnections() {
        console.log("Connecting WebSocket", this.config.client_wss);

        this._ws = new WebSocket(this.config.client_wss);
        this._ws.onopen = () => {
            this.emit('_ws_connected');
            this.emit('ws_connected');
        }
        this._ws.onerror = (err) => {
            this.emit('error_init', {status: err.code, text: err.reason});
            this.emit('ws_error_init', {status: err.code, text: err.reason});
        };
        this._ws.onmessage = (msg) => this._onMessage(msg);
        this._ws.onclose = () => {
            this.emit('_ws_closed');
            this.emit('ws_closed');
            this._ws = undefined;
            this.terminate();
        }
    }

    _initAPIMethods(methods) {
        // Dynamically generate api methods based on data
        // received from a server.
        this._api_methods = methods;
        // Must be let because var and const leads to wrong result.
        for (let apiFn of this._api_methods) {
            this[apiFn] = (args={}) => {
                args.type = apiFn;
                this._ws.send(JSON.stringify(args));
            };
        }
    }

    _initAPIEvents(events) {
        //Dynamically set the list of api events based on data from server
        this._api_events = events;
    }

    _onMessage(msg) {
        const data = JSON.parse(msg.data);

        switch (data.type) {

            case 'asr_paused':
                this.isRecognizing = false;
                this.emit(data.type, data);
                break;

            case 'asr_recognizing':
                this.isRecognizing = true;
                this.emit(data.type, data);
                break;

            case 'asr_ready':
                this._logSIPAudio();
                this.emit(data.type, data);
                break;


            case 'sc_start_session':
                this._initAPIMethods(Object.keys(data.schema.methods));
                this._initAPIEvents(Object.keys(data.schema.events));
                this.emit('_ws_session', {type: 'started', id: data.session_id});
                this.emit('ws_session', {type: 'started', id: data.session_id});

                console.log('SpeechCloud session started, session_id', data.session_id);
                console.log('SpeechCloud session_uri', data.session_uri);
                console.log('SpeechCloud schema_uri', data.schema_uri);
                this.speechcloud_session_started = true;
                this.speechcloud_audio_constraints = (data?.session_parameters?.worker?.webrtc) || {};

                if (!this.initialize_sip_later) {
                    this.initializeSIP()
                };

                this.emit(data.type, data);
                break;

            default:
                this.emit(data.type, data);
        }
    }

}



