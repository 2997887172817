(function webpackUniversalModuleDefinition(root, factory) {
	//SpeechCloud library
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	//SpeechCloud library
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	//SpeechCloud library
	else if(typeof exports === 'object')
		exports["speechcloud"] = factory();
	//SpeechCloud library
	else
		root["speechcloud"] = factory();
})(self, () => {
return 